body {
  margin: 0;
  background-color: #242629;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cardImage:after {
  content: '\A';
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  iframe {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 3vh;
    height: 35vh;
    width: 95%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  iframe {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2vh;
    height: 35vh;
    width: 50%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  iframe {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2vh;
    height: 27vh;
    width: 55%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  iframe {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2vh;
    height: 35vh;
    width: 65%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  iframe {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2vh;
    height: 30vh;
    width: 45%;
  }
}
